<template>
	<div>
		<div class="person_infor">
			<div class="login_top">
				<!-- <div class="img"> <img src="@/assets/login/flag.png"></div> -->
				<div class="login_title">{{$t('verify')}}</div>
			</div>
		</div>
		<div class="select_radio">
			<el-radio-group v-model="curSelect" text-color="#F39800" fill="#F39800">
				<el-radio :label="1">{{$t('mobileVerify')}}</el-radio>
				<el-radio :label="2">{{$t('emailVerify')}}</el-radio>
			</el-radio-group>
		</div>
		<div class="input_item" v-if="curSelect==1">
			<div class="input_item_icon">
				<img src="../../../assets/login/phone.png">
			</div>
			<div class="dropdown">
				<el-dropdown @command="areaCodeClick">
					<span class="el-dropdown-link">
						{{mobile_code}}<i class="el-icon-caret-bottom el-icon--right"></i>
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item :command="item" v-for="(item,index) in areaCodeList" :key="index">{{item}}
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
			<div class="input">
				<input type="text" v-model="mobile" :placeholder="$t('login.inputPhone')">
			</div>
		</div>

		<div class="input_item" v-if="curSelect==2">
			<div class="input_item_icon">
				<img src="../../../assets/login/email.png">
			</div>
			<div class="input">
				<input type="text" :placeholder="$t('login.inputEmail')" v-model="user_email">
			</div>
		</div>

		<div class="input_item">
			<div class="input_item_icon">
				<img src="../../../assets/login/code.png">
			</div>
			<div class="input">
				<input type="text" v-model="code" maxlength="6" :placeholder="$t('login.inputCode')">
			</div>
			<div class="code">
				<div class="code1" @click="getCode" v-if="!isGetCode">{{$t('login.getCode')}}</div>
				<div class="code2" v-else>{{$t('login.emailSendAgain')}}</div>
			</div>
		</div>

		<div class="important_tips">
			{{$t('importantTips')}}
		</div>

		<div class="btn" @click="confirmClick">
			{{$t('nextStep')}}
		</div>
	</div>
</template>

<script>
	import bus from '@/common/function/bus.js'

	export default {
		data() {
			return {
				mobile: '',
				code: '',
				areaCodeList: [],
				mobile_code: '',

				time: 60 * 10,
				isGetCode: false, //是否获取验证码
				timer: '', //定时器

				curSelect: 1,

				user_email: '',

				userInfor: '',
			}
		},
		mounted() {
			this.init();
		},
		watch: {
			curSelect() {
				this.code = '';
				clearInterval(this.timer);
				this.isGetCode = false;
			},
		},
		destroyed() {
			clearInterval(this.timer);
		},
		methods: {
			async init() {
				await this.getUserInfo();
				await this.getAreaCode();
			},
			// 获取会员信息
			getUserInfo() {
				return new Promise((resolve, reject) => {
					this.$http.userInfo().then(res => {
						if (res.code == 1) {
							this.userInfor = res.data;
							this.user_email = this.userInfor.user_email;
							this.mobile = this.userInfor.mobile;
							this.mobile_code = this.userInfor.mobile_code;
						} else if (res.code == -201) {
					
				} else {
							this.$message.error(res.msg);
						}
						resolve();
					})
				})
			},
			// 获取区号
			getAreaCode() {
				return new Promise((resolve, reject) => {
					this.$http.getAreaCode().then(res => {
						if (res.code == 1) {
							this.areaCodeList = res.data;
							if (!this.mobile) {
								this.mobile_code = res.data[0];
							}
						} else {
							this.$message.error(res.data.msg);
						}
						resolve();
					}).catch((v) => {
						console.log(v);
					});
				})
			},
			// 区号点击
			areaCodeClick(command) {
				this.mobile_code = command;
			},
			// 获取验证码
			getCode() {
				if (!this.mobile && this.curSelect == 1) {
					this.$message.error(this.$t('tips.mobilePhoneNumberCannotBeEmpty'));
					return;
				}

				if (!this.user_email && this.curSelect == 2) {
					this.$message.error(this.$t('tips.emailNumberCannotBeEmpty'));
					return;
				}


				let data = {
					type: 7,
					is_test: 0,
				}


				if (this.curSelect == 1) {
					this.$set(data, "mobile", this.mobile);
					this.$set(data, "mobile_code", this.mobile_code);
				} else {
					this.$set(data, "user_email", this.user_email);
				}

				this.$http.getCode(data).then(res => {
					if (res.code == 1) {
						this.$message.success(this.$t('sendSuccess'));
						this.isGetCode = true;
						this.timer = setInterval(() => {
							if (this.time == 1) {
								clearInterval(this.timer);
								this.time = 60 * 1;
								this.isGetCode = false;
							}
							this.time--;
						}, 1000);
					} else {
						this.$message.error(res.msg);
					}
				}).catch((v) => {

				});
			},
			confirmClick() {
				if (!this.mobile && this.curSelect == 1) {
					this.$message.error(this.$t('tips.mobilePhoneNumberCannotBeEmpty'));
					return;
				}

				if (!this.user_email && this.curSelect == 2) {
					this.$message.error(this.$t('tips.emailNumberCannotBeEmpty'));
					return;
				}


				if (!this.code) {
					this.$message.error(this.$t('tips.codeCannotBeEmpty'))
					return;
				}

				let data = {
					code: this.code,
					type: 7
				}

				if (this.curSelect == 1) {
					this.$set(data, "mobile", this.mobile);
					this.$set(data, "mobile_code", this.mobile_code);
				} else {
					this.$set(data, "user_email", this.user_email);
				}

				this.$http.isVerify(data).then(res => {
					if (res.code == 1) {
						this.$router.push({
							path: '/personInfor',
							query: {
								curCenterIndex: 0,
							},
						})
						bus.$emit('personInforEmit');
					} else {
						this.$message.error(res.msg);
					}
				})
			}
		}
	}
</script>

<style scoped="" lang="less">
	.person_infor {
		width: 100%;
	}

	.login_top {
		display: flex;
		padding: 24px;
		border-bottom: 1px solid #F3F5F6;

		.img {
			display: flex;
			align-items: center;

			img {
				width: 18px;
				height: 18px;
			}
		}

		.login_title {
			margin-left: 8px;
			font-weight: bold;
			font-size: 18px;
		}
	}

	.cur_account {
		color: #333333;
		font-size: 16px;
		text-align: left;
		margin-top: 60px;
	}

	.dropdown {
		margin-left: 24px;

		&:hover {
			cursor: pointer;
		}
	}

	.input_item {
		margin-left: 32px;
		margin-bottom: 32px;
		text-align: left;
		display: flex;
		width: 540px;
		padding: 14px 24px;
		border: 1px solid #DEDEDE;
		font-size: 16px;
		position: relative;


		.input_item_icon {
			img {
				width: 20px;
				height: 20px;
			}
		}

		.input {
			padding-left: 16px;

			input {
				width: 400px;
				border: none;
				outline: none;
				font-size: 16px;
			}
		}

		.code {
			position: absolute;
			top: 13px;
			right: 24px;
			color: #4177DD;
			font-size: 16px;

			&:hover {
				cursor: pointer;
			}

		}

		.show_icon {
			position: absolute;
			top: 13px;
			right: 24px;

			img {
				width: 18px;
				height: 18px;
			}
		}
	}

	.btn {
		margin-left: 32px;
		width: 216px;
		text-align: center;
		height: 48px;
		line-height: 48px;
		background: #F39800;
		opacity: 1;
		border-radius: 24px;
		font-size: 16px;
		color: #FFFFFF;

		&:hover {
			cursor: pointer;
		}
	}

	.el-dropdown-menu {
		// width: 100px;
		max-height: 150px !important;
		overflow-y: scroll;
		overflow-x: hidden;
	}

	.select_radio {
		margin: 24px 32px;
		text-align: left;
	}

	.important_tips {
		width: 590px;
		text-align: left;
		margin-left: 32px;
		color: #b8b8b8;
		font-size: 14px;
		margin-bottom: 36px;
	}
</style>
